.ttype_1 {
  background: #67c23a;
  color: #fff;
}
.ttype_2 {
  background: #e6a23c;
  color: #fff;
}
.ttype_3 {
  background: #409eff;
  color: #fff;
}
.ttype_4 {
  background: #F56C6C;
  color: #fff;
}
.ovy-a > div {
  overflow-y: scroll;
  min-height: 300px;
}
.ovy-a > div::-webkit-scrollbar {
  width: 0 !important;
}
.personalSpecialOrder {
  border-bottom: 1px solid transparent;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.el-container {
  min-height: 160px;
  overflow: hidden;
  border-bottom: 1px solid #eee;
  margin-bottom: 14px;
}
.el-container:last-child {
  border-bottom: 0;
}
.el-container .el-aside {
  width: 200px;
  background: #f9f9f9;
  padding: 20px 0;
  float: left;
}
.el-container .el-aside .timg {
  width: 80px;
  height: 80px;
  display: block;
  margin: auto;
  border-radius: 50%;
}
.el-container .el-aside .t {
  width: 100px;
  margin: auto;
  text-align: center;
  margin-top: 10px;
}
.el-container .el-aside .ttype {
  width: 70px;
  margin: auto;
  text-align: center;
  margin-top: 4px;
  font-size: 12px;
  border-radius: 4px;
  height: 22px;
  line-height: 22px;
}
.el-container .el-main {
  width: 80%;
  height: 100%;
  float: left;
  padding-bottom: 6px;
}
.el-container .el-main .content {
  min-height: 110px;
}
.el-container .el-main .operation {
  text-align: right;
  height: 25px;
  line-height: 25px;
}
.el-container .el-main .operation .jb {
  width: 16px;
  vertical-align: middle;
  cursor: pointer;
}
.el-container .el-main .operation .sj {
  color: #909090;
  padding: 0 5px;
  font-size: 12px;
}
.el-container .el-main .operation .sc {
  padding: 0 5px;
  cursor: pointer;
}
.el-container .el-main .operation .sc:hover {
  color: red;
}
.el-container .el-main .operation .syj {
  padding: 0 5px;
  color: #909090;
  font-size: 12px;
  cursor: pointer;
}
.el-container .el-main .operation .syj:hover {
  color: #4089fa;
}
.el-container .el-main .operation .hf {
  color: #4089fa;
  padding: 0 5px;
  cursor: pointer;
  font-size: 12px;
  background: #f7f7f7;
}
.el-container .el-main .replyContent {
  overflow: hidden;
  background: #f7f7f7;
  padding: 10px 20px;
}
.el-container .el-main .replyContent .pl {
  clear: both;
  border-bottom: 1px solid #f1f1f1;
  margin-bottom: 6px;
  overflow: hidden;
}
.el-container .el-main .replyContent .pl .tx {
  width: 30px;
  height: 30px;
  float: left;
  margin-right: 10px;
  border-radius: 50%;
}
.el-container .el-main .replyContent .pl .hxm {
  font-weight: 6000;
  float: left;
  height: 25px;
  line-height: 20px;
  margin-top: 5px;
  margin-right: 5px;
  font-size: 12px;
}
.el-container .el-main .replyContent .pl .xm {
  font-weight: 6000;
  float: left;
  height: 25px;
  line-height: 20px;
  margin-top: 5px;
  margin-right: 5px;
  font-size: 12px;
}
.el-container .el-main .replyContent .pl .nr {
  float: left;
  line-height: 20px;
  margin-top: 5px;
  font-size: 12px;
}
.el-container .el-main .replyContent .pl .sj {
  float: right;
  height: 25px;
  line-height: 20px;
  margin-top: 5px;
  margin-right: 5px;
  color: #909090;
  font-size: 12px;
}
.el-container .el-main .replyContent .pl .ttype {
  width: 60px;
  text-align: center;
  float: right;
  height: 20px;
  line-height: 20px;
  padding: 0 4px;
  font-size: 12px;
  border-radius: 4px;
  margin-top: 5px;
  margin-right: 5px;
}
