




































































































































































































































.ttype_1 {
  background: #67c23a;
  color: #fff;
}

.ttype_2 {
  background: #e6a23c;
  color: #fff;
}

.ttype_3 {
  background: #409eff;
  color: #fff;
}

.ttype_4 {
  background: #F56C6C;
  color: #fff;
}

.ovy-a>div {
  overflow-y: scroll;
  min-height: 300px;
  &::-webkit-scrollbar { 
    width: 0 !important 
  }
}

.personalSpecialOrder {
  border-bottom: 1px solid transparent;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.el-container {
  min-height: 160px;
  overflow: hidden;
  border-bottom: 1px solid #eee;
  margin-bottom: 14px;

  &:last-child {
    border-bottom: 0;
  }

  .el-aside {
    width: 200px;
    background: #f9f9f9;
    padding: 20px 0;
    float: left;

    .timg {
      width: 80px;
      height: 80px;
      display: block;
      margin: auto;
      border-radius: 50%;
    }

    .t {
      width: 100px;
      margin: auto;
      text-align: center;
      margin-top: 10px;
    }

    .ttype {
      width: 70px;
      margin: auto;
      text-align: center;
      margin-top: 4px;
      font-size: 12px;
      border-radius: 4px;
      height: 22px;
      line-height: 22px;
    }
  }

  .el-main {
    width: 80%;
    height: 100%;
    float: left;
    padding-bottom: 6px;

    .content {
      min-height: 110px;
    }

    .operation {
      text-align: right;
      height: 25px;
      line-height: 25px;

      //   background: #f7f7f7;
      .jb {
        width: 16px;
        vertical-align: middle;
        cursor: pointer;
      }

      .sj {
        color: #909090;
        padding: 0 5px;
        font-size: 12px;
      }

      .sc {
        padding: 0 5px;
        cursor: pointer;

        &:hover {
          color: red;
        }
      }

      .syj {
        padding: 0 5px;
        color: #909090;
        font-size: 12px;
        cursor: pointer;

        &:hover {
          color: #4089fa;
        }
      }

      .hf {
        color: #4089fa;
        padding: 0 5px;
        cursor: pointer;
        font-size: 12px;
        background: #f7f7f7;
      }
    }

    .replyContent {
      overflow: hidden;
      background: #f7f7f7;
      padding: 10px 20px;

      .pl {
        clear: both;
        border-bottom: 1px solid #f1f1f1;
        margin-bottom: 6px;
        overflow: hidden;

        .tx {
          width: 30px;
          height: 30px;
          float: left;
          margin-right: 10px;
          border-radius: 50%;
        }

        .hxm {
          font-weight: 6000;
          float: left;
          height: 25px;
          line-height: 20px;
          margin-top: 5px;
          margin-right: 5px;
          font-size: 12px;
        }

        .xm {
          font-weight: 6000;
          float: left;
          height: 25px;
          line-height: 20px;
          margin-top: 5px;
          margin-right: 5px;
          font-size: 12px;
        }

        .nr {
          float: left;
          line-height: 20px;
          margin-top: 5px;
          font-size: 12px;
        }

        .sj {
          float: right;
          height: 25px;
          line-height: 20px;
          margin-top: 5px;
          margin-right: 5px;
          color: #909090;
          font-size: 12px;
        }

        .ttype {
          width: 60px;
          text-align: center;
          float: right;
          height: 20px;
          line-height: 20px;
          padding: 0 4px;
          font-size: 12px;
          border-radius: 4px;
          margin-top: 5px;
          margin-right: 5px;
        }
      }
    }
  }
}